body {
    height: 100%;
    margin:0px;
    background: url(/img/bg-grey.jpg) no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
    font-family: "Helvetica Narrow","Arial Narrow",Tahoma,Arial,Helvetica,sans-serif;
    letter-spacing: 1px;
    font-size: 16px;
}

footer {
    background-color: #fff;
    padding: 20px 0px;
}

@media (max-width: 767px){
  footer {
    text-align: center;
  }  
}

.header {
    #logo {
        width: 100%;
        height: auto;
    }
    .contact {
        font-size: 16px;
        text-transform: uppercase;
        a {
            color: #333;
        }
    }
    @media (max-width: 767px){
       #logo {
            max-width: 125px;
        } 
    }
}

.title {
    padding-top: 10px;
    padding-bottom: 10px;
    h1 {
        font-weight: 900;
        text-transform: uppercase;
    }
    h2 {
        font-size: 20px;
        margin-top: 5px;
    }
}

.btn-facebook {
    color: #fff;
    background-color: #4C67A1;
    &:hover {
        color: #fff;
        background-color: #405D9B;
    }
    &::focus {
        color: #fff;
    }
}

.navbar {
    border-radius: 0 !important;
}

@media (min-width: 767px)
{
    .navbar-fixed-top {
        position: relative;
    }
}

.navbar-nav {
    text-transform: uppercase;
    font-weight: 800;
}


@media (min-width: 767px){
    .navbar {
        background-color: #bfbfbf;
        -webkit-box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.5);
        box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.5);
        border: none;
    }
    .navbar-nav {
        float:none;
        margin:0 auto;
        display: block;
        text-align: center;
    }

    .navbar-nav > li {
        display: inline-block;
        float:none;
        &:hover {
            background-color: #1a648c;
        }
    }

    .navbar-default .navbar-nav > .active > a {
        color: #fff;
        background-color: #1a648c;
    }

    .nav > li > a {
        padding-left: 40px;
        padding-right: 40px;
    }
    .navbar-default .navbar-nav > li > a {
        color: #333;
        &:hover {
            color: #fff;
        }
    }
    .navbar-nav > li {
        background-color: #e0e0e0;
        border-left: 1px solid rgba(151, 151, 151, 1);
    }

}

#home {
    margin-bottom: 30px;
    img {
        display: block;
        max-width: 100%;
        height: auto;
        border: 4px solid #fff;
        outline: 2px solid #e8e8e8;
        outline-offset: -10px;
    }
    .h3-box {
        background-color: #3a3a3a;
        border: 6px solid #6d6b6b;
        padding: 5px 5px 15px 5px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 20px;
        &:hover {
            background-color: #1a648c;
        }
        h3 {
            line-height: 20px;
            font-size: 18px;
            color: white;
            text-transform: uppercase;
        }
    }
}

#contactsuccess {
    color: green;
    border:1px solid green;
    display:none;
    text-align:center;
    padding:15px;
    font-weight:600;
    margin-bottom:15px;
}

#contacterror {
    color: red;
    display:none;
    border:1px solid red;
    text-align:center;
    padding:15px;
    font-weight:600;
    margin-bottom:15px;
}

#contactsuccess.show, #contacterror.show, .show {
    display:block;
}

.fa-circle-icon {
    background: #01293f;
    padding: 20px;
    border-radius: 50%;
    margin: 0 5px;
    color: #17b2d0;
    font-size: 20px;
    width: 60px;
    &:hover {
        color: #49d4ef;
    }
}

.google-ratings {
    width: 75px;
    height: auto;
    border: 1px solid #9a9a9a;
    border-radius: 5px;
}

.blue-box {
    background-color: rgba(91, 141, 168, .9);
    color: #fff;
    margin-bottom: 20px;
    padding: 15px 25px;
    min-height: 325px;
    .description {
        height: 20px;
    }
}

.white-box {
    background-color: rgba(255, 255, 255, .9);
    color: #fff;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 15px 25px;
    color: #000;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px 0px;
    .price {
        color: #36B5CD;
        font-size: 24px;
    }
    h3 {
        margin-top: 10px;
    }
}

.gallery {
    h2 {
        margin-bottom: 30px;
    }
    img {
        width: 100%;
        height: auto;
    }

    .img-thumbnail {
        width: 300px; /* You can set the dimensions to whatever you want */
        height: 400px;
        object-fit: cover;
    }
}


.album {
    img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
    @media (min-width: 767px){
        a img:hover {
            transform: scale(1.1);
            transition: transform .2s;
            -webkit-filter: drop-shadow(5px 5px 5px #222);
            filter: drop-shadow(5px 5px 5px #222);
        }
    }

    .img-thumbnail {
        width: 250px; /* You can set the dimensions to whatever you want */
        height: 250px;
        object-fit: cover;
    }
}

.btn-primary {
    border-radius: 0;
    font-size: 16px;
    &.full-width {
        width: 100%;
    }
}

#product {
    .full-description {
        font-size: 20px;
    }
    .btn-primary {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

#services, #store {
    @media (min-width: 767px){
        .img-responsive {
            width: 250px; /* You can set the dimensions to whatever you want */
            height: 250px;
            object-fit: cover;
        }
        .btn-primary {
            margin-top: 20px;
        }
    }
}

@media (max-width: 425px){
    .bs-stepper-header {
        display: block !important;
    }
}